@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.textarea {
	width: 100%;
	textarea {
		padding: 12px;
		min-height: 80px;
		border-radius: 11px;
		&:disabled {
			background-color: #f4f4f5;
		}
	}
	&__margin {
		margin-bottom: 14px;
	}
}

.fieldset {
	text-align: left;
	position: absolute;
	top: -5px;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0;
	padding: 0 8px;
	pointer-events: none;
	overflow: hidden;
	min-width: 0;
	border-color: rgba(255, 255, 255, 0.23);
	border: 1px solid #d8d8dd;
	border-radius: 11px;
	transition: border-color 0.3s;
	@include hover {
		border-color: $color-black-60;
	}
	&__error {
		border: 1px solid $color-red !important;
	}
	&__focus {
		border: 1px solid $color-black;
	}
}

.legend {
	width: auto !important;
	float: unset;
	width: auto;
	overflow: hidden;
	display: block;
	padding: 0;
	height: 11px;
	font-size: 15px;
	visibility: hidden;
	max-width: 0.01px;
	white-space: nowrap;
	transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	&__active {
		font-size: 12.5px !important;
		max-width: 100%;
		transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
	}
}

.label {
	font-size: 15px;
	padding: 0 5px;
	display: block;
	transform-origin: left top;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: absolute;
	left: -5px;
	top: 23px;
	color: #777778;
	transform: translate(14px, -50%) scale(1);
	transition:
		color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
		transform 200ms,
		max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	z-index: 1;
	pointer-events: none;
	&__active {
		transform: translate(15px, -150%) scale(0.8);
		user-select: none;
	}
	&__error {
		color: $color-red;
	}
	&__focus {
		color: $color-black;
	}
}
