@import "../../assets/scss/mixins";

.container {
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 16px;
  max-width: 960px;
  gap: 140px;

  @include respond-below(sm) {
    gap: 80px;
  }
}