@import "variables.scss";
@import "phone-input.scss";
@import "ant.css";

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px $color-white inset;
}

.os-theme-dark {
  --os-handle-bg: #d8d8dd;
  --os-handle-border-radius: 3px;
  --os-padding-perpendicular: 4px;
  --os-size: 14px;
}

:root {
  --scroll-width: 0px;
}

.webchat-icon {
  display: none !important;
}

iframe[class*="webchat-js-container:"]{
  display: none;
}
