@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.wrapper{
  legend {
    width: auto;
  }

  i {
    display: none;
  }
}