@import '../../assets/scss/mixins';
@import '../../assets/scss/mixins';

.button {
	align-self: flex-start;
	outline: 0;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-family: Inter, sans-serif;
	font-weight: 500;
	transition: transform 0.1s ease-out;
	gap: 4px;
	border-radius: 50px;

	&:active {
		transform: scale(0.96);
	}

	&_type {
		&_primary {
			background-color: #1c1c1e;
			color: #ffffff;

			&:hover {
				background-color: #2e2e30;
			}

			&:active {
				background-color: #4f4f51;
			}

			&:disabled {
				background-color: #ddddde;
			}
		}

		&_secondary {
			background-color: #f4f4f5;
			color: #1c1c1e;

			&:hover {
				background-color: #ededed;
			}

			&:active {
				background-color: #e5e5e5;
			}

			&:disabled {
				background-color: #fbfbfb;
			}
		}
	}

	&_size {
		&_large {
			@include respond-above(sm) {
				padding: 16px 32px;
				font-size: 18px;
				line-height: 24px;
			}

			@include respond-below(sm) {
				padding: 12px 24px;
				font-size: 16px;
				line-height: 21px;
			}
		}

		&_medium {
			padding: 8px 16px;
			font-size: 15px;
			line-height: 21px;
		}
	}
}
