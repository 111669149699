@import "../../../assets/scss/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
  &__text {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &__btn{
    margin-top: 8px;
    background: #F4F4F4;
  }
  &__btn2{
    margin-bottom: -12px;
  }
}

.modalStyle {
  border-radius: 16px;
  overflow: hidden;
  max-width: 400px;
  [class="ant-modal-body"] {
    padding: 24px;
  }
}
