@import "../../assets/scss/mixins";

.title {
  font-style: normal;
  font-weight: 600;
  margin: 0;

  @include respond-above(sm) {
    font-size: 40px;
    line-height: 44px;
  }

  @include respond-below(sm) {
    font-size: 28px;
    line-height: 34px;
  }
}