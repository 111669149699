@import '../../../../assets/scss/mixins';

.overlay {
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background: rgba(26, 26, 26, 0.32);
}
