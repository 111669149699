$color-overflow-t: rgba(28, 28, 30, 0.32);

$color-black: #1c1c1e;
$color-black-92: #2e2e30;
$color-black-60: #757576;
$color-black-60-t: rgba(28, 28, 30, 0.6);
$color-black-44: #9b9b9c;
$color-black-44-t: rgba(28, 28, 30, 0.44);

$color-white: #ffffff;
$color-white-72-t: rgba(255, 255, 255, 0.72);

$color-gray: #72767d; //70%
$color-gray-lighter: #72757c;
$color-gray-dark: #5b5e67;
$color-gray-darkness: #363b45;
$color-gray-light: #9a9da2; //50%
$color-gray-light-bg: #f9f9f9;
$color-gray-stroke: #d8d8d8;
$color-gray-disabled: #c3c4c7;
$color-background-1: #f4f4f4;
$color-background-2: #ebebeb;

$color-gray-1: #b5b5b8;
$color-gray-2: #cacacb;
$color-gray-3: #d8d8dd;
$color-gray-4: #ececf0;
$color-gray-5: #f0f0f5;

$color-background: #f7f7f8;

$color-green-dark: #07652d;
$color-green: #02b875;
$color-green-10: #e6f8f2;
$color-green-10-t: rgba(2, 184, 117, 0.1);
$color-green-light: #80bf9a;
$color-green-light-delivery: #8fc658;

$color-cyan: #00b8d4;
$color-blue: #0a84ff;
$color-blue-hover: #086fd6;
$color-blue-dark: #0058a6;
$color-blue-light: #2f94ed;
$color-blue-12-t: rgba(10, 132, 255, 0.12);
$color-blue-60-t: rgba(10, 132, 255, 0.6);

$color-red: #ff3b30;
$color-red-8-t: rgba(255, 59, 48, 0.08);
$color-red-dark: #da250e;
$color-red-lighter: #fa614e;
$color-red-light: #faa99f;
$color-red-super-light: #fdf6f5;
$color-warning: #fa614e4d;

$color-yellow: #ff9f0a;
$color-yellow-light: #fbdb9c;
$color-yellow-dark: #f4a60b;
$color-yellow-bright: #ffbf42;

$color-violet: #944cf1;
$color-violet-light: #b581f8;

$shadow-3: 0 2px 12px rgba(125, 131, 137, 0.08);

$shadow-elevation-1: 0px 2px 6px rgba(28, 28, 30, 0.08);
$shadow-elevation-2: 0px 4px 16px rgba(28, 28, 30, 0.08);
