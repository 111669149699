@import '../../../../assets/scss/mixins';

.header {
	z-index: 1;
	position: sticky;
	inset: 0;
	padding: 16px;
	background-color: #fff;
	border-bottom: 1px solid #ececf0;

	@include respond-below(sm) {
		border: none;
	}

	&_hidden {
		@include respond-below(xs) {
			opacity: 0;
			z-index: -1;
		}
	}
}

.logo {
	width: 155px;
	height: 24px;
}

.btns {
	display: flex;
	align-items: center;
	gap: 16px;
}

.btn {
	display: flex;
	padding: 0;
	line-height: 1;
	background-color: #fff;
	border: none;
	cursor: pointer;

	@include respond-below(xs) {
		height: 24px;
	}

	&__menu {
		display: none;

		@include respond-below(sm) {
			display: block;
		}
	}
}

.wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1416px;
	margin: 0 auto;

	& a {
		font-size: 0;
	}
}
