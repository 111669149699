@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.wrapper {
  position: relative;
  display: flex;
}

.select[class~="ant-select-disabled"]{
  background: $color-background-1 !important;
  border-radius: 11px;
}

.select {
  width: 100%;
  [class="ant-select-selector"] {
    height: 44px !important;
    border-radius: 11px !important;
    border: 0px solid white !important;
    padding: 0 14px !important;
    overflow: hidden;
    background-color: transparent !important;
    [class="ant-select-selection-overflow"] {
      flex-flow: nowrap;
    }
    [class="ant-select-selection-item"] {
      line-height: 43px !important;
      border-radius: 8px !important;
      align-items: center;
    }
  }
  input {
    margin-left: 4px !important;
    margin-top: 2px !important;
  }
  &__multiple {
    :global {
      .ant-select-selection-search {
        margin-left: 0;
        .ant-select-selection-search-input {
          height: fit-content;
          margin-left: 0 !important;
        }
      }
    }
  }
}

.fieldset {
  text-align: left;
  position: absolute;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  overflow: hidden;
  min-width: 0;
  border-color: rgba(255, 255, 255, 0.23);
  border: 1px solid #d8d8dd;
  border-radius: 11px;
  transition: border-color 0.3s;
  &__error {
    border: 1px solid $color-red !important;
  }
  &__focus {
    border: 1px solid $color-black;
  }
}

.legend {
  width: auto !important;
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 15px;
  visibility: hidden;
  max-width: 0.01px;
  white-space: nowrap;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  &__active {
    font-size: 13px !important;
    max-width: 100%;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  }
}

.label {
  font-size: 15px;
  padding: 0 5px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: -5px;
  top: 50%;
  color: #777778;
  transform: translate(14px, -50%) scale(1);
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  &__active {
    transform: translate(15px, -150%) scale(0.8);
    user-select: none;
  }
  &__error {
    color: $color-red;
  }
  &__focus {
    color: $color-black;
  }
}

.popup {
  border-radius: 12px;
  padding: 0;
  [class*="ant-select-item-option-selected"] {
    background: $color-white;
    color: $color-blue;
  }
  [class*="ant-select-item-option-active"] {
    background: $color-background-1;
  }
  [class*="ant-select-item ant-select-item-option"] {
    display: flex;
    padding: 10px 12px;
    [class*="ant-select-item-option-content"] {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.icon {
  font-size: 20px;
  transition:
    transform 0.2s,
    color 0.3s;
  &__open {
    transform: rotate(180deg);
    color: $color-black;
  }
}
