@import '../../../../assets/scss/mixins';

.blockForNew {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	margin-bottom: 48px;

	@include respond-below(sm) {
		grid-template-columns: 1fr;
	}

	@include respond-below(xs) {
		margin-bottom: 32px;
	}

	&__inner {
		margin-bottom: 24px;
	}

	.wrapper {
		padding: 32px 24px;
		min-height: 120px;
		background-size: 60px;
		background-repeat: no-repeat;
		background-position: top 32px right 24px;

		@include respond-below(xs) {
			background-position: top 24px right 16px;
			padding: 24px 16px;
		}

		&__inner {
			padding-right: 108px;

			@include respond-below(xs) {
				padding-right: 84px;
			}
		}
	}
}

.analytics {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	margin-bottom: 40px;

	.wrapper {
		margin-bottom: 0;
	}
}

.wrapper {
	padding: 24px;
	border-radius: 16px;
	cursor: pointer;

	h3 {
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 10px;
	}

	span {
		font-size: 15px;
	}
}

.heading {
	padding-top: 48px;
	margin-bottom: 32px;

	font-size: 24px;
	font-weight: 600;
	line-height: 28px;

	@include respond-below(xs) {
		padding-top: 0;
		margin-bottom: 24px;
	}
}

.gray {
	background-color: #f0f0f5;
}

.pink {
	background-color: rgba(170, 0, 255, 0.08);
}

.blue {
	background-color: rgba(10, 132, 255, 0.12);
}

.link {
	margin-bottom: 32px;
	padding: 0;
	font-family: Inter, sans-serif;
	font-size: 15px;
	line-height: 20px;
	font-weight: 500;
	color: #0071e6;
	background-color: transparent;
	border: 0;
	transition: color 0.2s;
	cursor: pointer;

	@include hover {
		color: #5199f2;
	}
}
