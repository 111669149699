@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.radio {
  margin-bottom: 12px;
}

.upload {
  order: 2;
  border-radius: 12px !important;
  [class="ant-upload-drag-container"] {
    padding: 0 10px;
  }
  &__hide {
    display: none;
  }
}

.ant-upload-hint {
  display: flex;
  align-items: center;
  gap: 7px;
}

.weapperUpload {
  & > span {
    display: flex;
    flex-direction: column;
    [class="ant-upload-list ant-upload-list-picture-card"] {
      & > div {
        width: 85px;
        height: 115px;
        margin-bottom: 24px;
      }
    }
    [class="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-picture-card"] {
      border-radius: 12px !important;
      padding: 0;
      border: 1px solid $color-gray-3;
      overflow: hidden;
    }
  }
}

.wrapperItems {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.wrapperItem {
  width: 200px;
  height: 200px;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

  .wrapperImg {
    display: flex;
    width: 100%;
    overflow-x: auto;
    &__isNoteDelete{
      margin-bottom: 0;
    }
    &__base {
      transition: opacity 0.3s;
      position: relative;
      &__active {
        opacity: 0.5;
        z-index: 0;
      }
      i {
        color: gray;
        position: relative;
        right: -5px;
      }
      &:hover {
        .wrapperImg__base__icon {
          opacity: 1;
          pointer-events: initial;
        }
      }
      &__icon {
        transition: opacity 0.3s;
        position: absolute;
        width: 20px;
        height: 20px;
        right: 8px;
        top: 8px;
        pointer-events: none;
        cursor: pointer;
        opacity: 0;
        z-index: 111;
        @include respond-below(md){
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
    &__main {
      display: flex;
      width: 200px;
      height: 300px;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid $color-gray-3;
      margin-right: 8px;
      position: relative;
      &::after {
        content: "Главное фото";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        display: flex;
        padding: 4px 12px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 12px;
        position: absolute;
        left: 12px;
        top: 12px;
        border-radius: 12px;
        background: $color-gray-3;
      }
      img {
        width: auto;
        height: auto;
        object-fit: cover;
      }
    }
    &__blocks {
      display: flex;
      margin-top: 10px;

    &__img {
      min-width: 85px;
      min-height: 115px;
      max-width: 85px;
      max-height: 115px;
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid $color-gray-3;
      padding: 5px;
      img {
        object-fit: cover;
      }
    }
  }
}

.error {
  border: 1px solid red;
}