@import '../../../../assets/scss/mixins';

.slide {
	position: relative;
	width: 100%;
	border-radius: 24px;

	@include respond-above(md) {
		background: url('../../../../../public/assets/images/busel-main.png') no-repeat center center;
		margin: 0 auto 140px;
		max-width: 1280px;
		height: 584px;
	}

	@include respond-below(md) {
		margin-bottom: 80px;
		height: 400px;
		background-size: auto 100%;
		background: url('../../../../../public/assets/images/main-overlay.png') no-repeat center center;
		background-color: #f4f4f5;
		background-size: cover;
	}
}


.content {
	margin: 0 auto;
	max-width: 960px;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-top: 74px;

	@include respond-above(md) {
		width: 960px;
	}

	@include respond-below(md) {
		padding: 48px 16px 0;
	}
}

.title {
	font-weight: 600;
	margin: 0;

	@include respond-above(md) {
		font-size: 72px;
		line-height: 78px;
	}

	@include respond-below(md) {
		font-size: 40px;
		line-height: 46px;
	}
}

.text {
	@include respond-above(md) {
		font-size: 24px;
		line-height: 30px;
		margin: 24px 0 56px;
		max-width: 520px;
	}

	@include respond-below(md) {
		font-size: 17px;
		line-height: 22px;
		margin: 16px 0 24px;
		max-width: 400px;
	}

	@include respond-below(sm) {
		max-width: 200px;
	}
}

.busel {
	position: absolute;
	bottom: -20%;
	right: 0px;
	width: auto;
	height: 454px;

	@include respond-above(md) {
		display: none;
	}

	@include respond-below(sm) {
		width: auto;
		height: 390px;
	}

	@include respond-below(xs) {
		width: auto;
		height: 351px;
	}
}
