@import "./assets/scss/mixins";

html,
body,
#root {
	width: 100%;
	@include respond-below(xxs) {
		width: 375px;
	}
}

body {
	margin: 0;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffffff;
	color: #1c1c1e;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

a {
	color: #0a84ff;
	text-decoration: none;

	@include hover {
		color: #0a84ff;
	}
}

figure {
	margin: 0px;
}
