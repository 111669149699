@import '../../assets/scss/mixins';

.modal_overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	inset: 0px;
	background: rgba(28, 28, 30, 0.32);
	z-index: 3;
}

.modal_container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #ffffff;
	z-index: 4;
	border-radius: 18px;
	width: 500px;

	@include respond-below(sm) {
		border-radius: 16px 16px 0px 0px;
		top: auto;
		width: 100%;
		bottom: 0px;
		left: 0px;
		transform: none;
	}
}

.modal_header {
	padding: 16px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include respond-below(sm) {
		padding: 16px;
	}
}

.title {
	font-size: 24px;
	font-weight: 600;
	line-height: 30px;
	margin: 0px;

	@include respond-below(sm) {
		font-size: 17px;
		line-height: 22px;
	}
}

.cross_button {
	outline: 0;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: transparent;
}

.cross_icon {
	width: 24px;
	height: 24px;

	& > rect {
		fill: none;
	}

	@include hover {
		& > path {
			stroke: #1c1c1e;
		}
	}
}

.table {
	width: 100%;
	padding: 0 20px;
	border-spacing: 0;

	@include respond-below(sm) {
		padding: 8px 16px 16px 16px;
	}
}

thead {
	font-size: 15px;
	font-weight: 500;
	line-height: 21px;

	@include respond-below(sm) {
		font-size: 13px;
		line-height: 16px;
	}
}

th {
	color: #777778;
	text-align: start;
	padding: 0px 12px;
}

tbody {
	tr {
		cursor: pointer;

		@include hover {
			background-color: #f4f4f5;
		}
	}
}


td {
	color: #1c1c1e;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	padding: 12px;
	border-bottom: 1px solid #ececf0;

	@include respond-below(sm) {
		font-size: 15px;
		line-height: 20px;
	}
}

.modal_footer {
	box-sizing: border-box;
	width: 100%;
	padding: 16px 20px;

	@include respond-below(sm) {
		padding: 8px 16px;
	}
}

.close_button {
	width: 100%;
}
