@import '../../assets/scss/mixins';

.badge {
	position: absolute;
	display: flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	background: #f4303b;
	color: #ffffff;
	font-size: 15px;
	font-weight: 500;
	line-height: 21px;
	border-radius: 55px;

	@include respond-above(md) {
		right: -16px;
		top: -18px;
		transform: rotate(4deg);
	}

	@include respond-below(md) {
		right: -9px;
		bottom: -9px;
		transform: rotate(-4deg);
	}
}

.calc {
	display: flex;

	@include respond-above(md) {
		justify-content: space-between;
	}

	@include respond-below(md) {
		flex-direction: column;
		gap: 24px;
	}
}

.inputs {
	display: flex;
	gap: 24px;
}

.label {
	color: #777778;
	font-weight: 500;

	@include respond-above(md) {
		font-size: 15px;
		line-height: 21px;
	}

	@include respond-below(md) {
		font-size: 13px;
		line-height: 16px;
	}
}

.item {
	display: flex;
	gap: 8px;
	flex-direction: column;
}

.value {
	font-weight: 500;

	@include respond-above(md) {
		font-size: 18px;
		line-height: 24px;
		margin-top: 16px;
	}

	@include respond-below(md) {
		font-size: 15px;
		line-height: 20px;
	}
}
