@import '../../assets/scss/mixins';

.input {
	padding: 12px 16px;
	border: 0;
	border-radius: 12px;
	background: #f4f4f5;
	box-sizing: border-box;
	outline: 0;
	font-family: 'Inter', sans-serif;
	font-weight: 400;

	@include respond-above(sm) {
		height: 56px;
		width: 208px;
		max-width: 208px;
		font-size: 18px;
		line-height: 24px;
	}

	@include respond-below(sm) {
		width: 100%;
		height: 44px;
		line-height: 20px;
		font-size: 15px;
	}
}
