@import "../../../../assets/scss/mixins";

.how {
  display: flex;
  flex-direction: column;

  @include respond-above(sm) {
    gap: 40px;
  }

  @include respond-below(sm) {
    gap: 24px;
  }
}

.item {
  display: flex;
  gap: 24px;
}

.image {
  border-radius: 100px;
  flex-shrink: 0;

  @include respond-above(sm) {
    width: 100px;
    height: 140px;
  }

  @include respond-below(sm) {
    width: 80px;
    height: 112px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-weight: 500;

  @include respond-above(sm) {
    font-size: 24px;
    line-height: 30px;
  }

  @include respond-below(sm) {
    font-size: 17px;
    line-height: 22px;
  }
}

.text {
  color: rgba(28, 28, 30, 0.60);

  @include respond-above(sm) {
    font-size: 18px;
    line-height: 24px;
  }

  @include respond-below(sm) {
    font-size: 15px;
    line-height: 20px;
  }
}