@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/variables';

.wrap {
	width: 800px;
	max-width: 100%;
	box-sizing: border-box;

	@include respond-below(xs) {
		margin-bottom: 24px;

		position: relative;
		z-index: 99;

		.header {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;

			display: flex;
			align-items: center;
			gap: 16px;
			width: 100%;
			padding: 8px 16px;
			background-color: #fff;
		}

		.search {
			flex-grow: 1;
			margin: 0;

			&__cancel {
				padding: 0;
				font-size: 15px;
				line-height: 20px;
				color: #777778;
				font-weight: 500;
				background-color: #fff;
				border: none;
				cursor: pointer;
			}
		}
	}

	* {
		box-sizing: border-box;
	}

	&:not(&__modal) {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		.result {
			flex-grow: 1;

			&__list {
				flex-grow: 1;
			}
		}

		.header {
			margin-top: 40px;

			@include respond-below(sm) {
				margin-top: 0;
			}
		}

		.content {
			padding-top: 40px;

			display: flex;
			flex-direction: column;
			min-height: 100%;

			@include respond-below(xs) {
				padding-top: 24px;
			}
		}
	}

	&__modal {
		z-index: 99999;
		position: fixed;
		top: 170px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		max-width: calc(100% - 48px);
		max-height: min(calc(90% - 170px), 468px);
		margin: 0 auto;
		background-color: #ffffff;
		border-radius: 16px;

		@include respond-below(sm) {
			// width: 560px;
			// max-width: calc(100% - 32px);

			@include respond-below(xs) {
				padding-top: 56px;
			}

			width: 100%;
			height: 100vh;
			max-width: 100%;
			max-height: 100%;

			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			transform: translateX(0);
			border-radius: 0;
		}

		.search {
			margin: 16px;

			&__inner {
				margin-bottom: 24px;
			}

			@include respond-below(xs) {
				margin: 0;
			}
		}

		.result {
			margin-bottom: 16px;

			&__title {
				margin: 0 16px 8px;
			}

			&__error {
				margin-bottom: 16px;
			}

			&__nothing {
				height: 0px;
				margin-bottom: 0;

				@include respond-below(xs) {
					height: auto;
				}
			}
		}

		.item {
			padding: 10px 16px;

			@include hover {
				background-color: #f4f4f5;
			}

			&__title {
				margin-bottom: 4px;
				font-size: 15px;
				line-height: 20px;
				font-weight: 400;
				color: #1c1c1e;
			}

			&__description {
				margin: 0;
				font-size: 12px;
				line-height: 14px;
				color: $color-black-60;
			}
		}
	}
}

.search {
	display: flex;
	align-items: center;
	gap: 12px;
	// margin: 0 0 40px;
	padding: 8px 12px;
	background-color: #f4f4f5;
	border-radius: 10px;

	&__icon {
		color: #9b9b9c;

		transition: all 0.3s;

		@include hover {
			color: $color-black-60;
		}
	}

	&__input {
		width: 100%;
		flex-grow: 1;
		padding: 0;
		font-size: 15px;
		line-height: 20px;
		color: #1c1c1e;
		background-color: transparent;
		border: none;
		outline: none;

		&::placeholder {
			color: $color-black-60;
		}

		@include respond-below(xs) {
			font-size: 16px;
			line-height: 21px;
		}
	}

	&__clean {
		display: flex;
		padding: 0;
		background-color: transparent;
		border: none;
		cursor: pointer;

		i {
			@include hover {
				color: $color-black-60;
			}
		}
	}
}

.result {
	&__title {
		margin: 0 0 24px;
		font-size: 13px;
		line-height: 16px;
		font-weight: 400;
		color: $color-black-60;

		@include respond-below(xs) {
			margin-bottom: 8px;
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	@include respond-below(xs) {
		padding-top: 24px;
	}
}

.item {
	cursor: pointer;

	&:not(.item__modal) {
		margin-bottom: 16px;
		padding: 32px 24px;
		border: 1px solid #d8d8dd;
		border-radius: 16px;

		&:last-of-type {
			margin-bottom: 24px;
		}

		@include respond-below(xs) {
			margin-bottom: 0;
			padding: 10px 0;
			border: none;
		}

		@include hover {
			border-color: $color-black-60;
		}

		.item__title {
			margin-bottom: 10px;
			font-size: 20px;
			line-height: 24px;
			font-weight: 600;
			color: #1c1c1e;

			@include respond-below(xs) {
				margin-bottom: 4px;
				line-height: 20px;
				font-size: 15px;
				font-weight: 400;
			}
		}

		.item__description {
			margin: 0 0 20px;
			font-size: 15px;
			line-height: 20px;
			color: #1c1c1e;

			@include respond-below(xs) {
				margin-bottom: 0;
				line-height: 14px;
				font-size: 12px;
				color: $color-black-60;
			}
		}
	}

	&__button {
		display: flex;
		align-items: center;
		padding: 0;
		padding-left: 32px;
		font-size: 15px;
		line-height: 20px;
		color: $color-black-60;
		background: url('../../../../../public/assets/images/faq/folder.svg') no-repeat top 0 left 0 #fff;
		border: none;
		cursor: pointer;

		@include hover {
			color: #b5b5b8;
		}
	}
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	margin: 24px 0 0;
	padding: 0;
	list-style-type: none;

	&__prev {
		transform: rotate(90deg);
	}

	&__next {
		transform: rotate(-90deg);
	}

	li:not([class*='ant-pagination-jump-next']) {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px !important;
		height: 36px !important;
		cursor: pointer;
		transition: none;

		&[class*='ant-pagination-item-active'] {
			border-color: #f4f4f5 !important;
			border-radius: 50%;
			background-color: #f4f4f5 !important;
		}

		@include hover {
			border-color: #f4f4f5 !important;
			border-radius: 50%;
			background-color: #f4f4f5 !important;
		}

		a,
		button {
			font-size: 15px;
			line-height: 20px;
			font-weight: 500;
			color: #1c1c1e;
		}
	}

	button {
		display: flex;
		padding: 0;
		background-color: #fff;
		border: none;
	}

	[class*='ant-pagination-disabled'] {
		opacity: 0;
		pointer-events: none;
	}

	[class*='ant-pagination-jump-next'] {
		&::after {
			content: '...';
			font-size: 15px;
			line-height: 20px;
			font-weight: 500;
			color: #1c1c1e;
		}

		* {
			display: none;
		}
	}
}

.error__title {
	font-size: 13px;
	line-height: 16px;
	font-weight: 400;
	color: $color-black-60;
}

.empty {
	max-width: 414px;
	margin: 48px auto 0;
	text-align: center;

	@include respond-below(sm) {
		margin-top: 48px;
	}

	&__title {
		margin-bottom: 8px;
		font-size: 20px;
		line-height: 24px;
		font-weight: 600;
	}

	&__text {
		font-size: 15px;
		line-height: 20px;
		color: #777778;
		max-width: 380px;
		width: 100%;
		margin: 0 auto;
	}

	&__link {
		color: #0071e6;

		@include hover {
			color: #5199f2;
		}
	}
}
