.title {
	display: block;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 8px;
}

.message {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	display: block;
}

.container {
	box-shadow: 0 2px 12px rgba(125, 131, 137, 0.08);
	border-radius: 10px !important;
	opacity: 1 !important;
}

.message {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	display: block;
}

.custom {
	max-width: 300px;
	text-align: left;

	@media (max-width: 767px) {
		max-width: 50vw;
	}
}

.tooltip {
	cursor: pointer;
	display: inline-flex;
}
