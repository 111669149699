@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.chat {
	position: fixed;
	right: calc(30px + var(--scroll-width));
	bottom: 24px;
	line-height: 0;
	z-index: 100;
	width: 56px;
	height: 56px;
	opacity: 1;
	transition: opacity 0.2s;

	@include respond-above(xxl) {
		right: revert !important;
		left: calc(50% + (732px - var(--scroll-width) / 2));
	}

	@include respond-below(md) {
		bottom: calc(16px + env(safe-area-inset-bottom));
		right: revert;
		left: 24px;
		width: 48px;
		height: 48px;

		&_bottom {
			bottom: 136px;
		}
	}

	@include respond-below(sm) {
		left: 16px;
	}

	&_with_cart {
		@media (min-width: 1361px) {
			right: calc(100px + var(--scroll-width));
		}

		@media (min-width: 1120px) and (max-width: 1199px) {
			right: calc(100px + var(--scroll-width));
		}
	}
}

.button {
	cursor: pointer;
	width: 56px;
	height: 56px;
	border-radius: 50%;
	padding: 0;
	background: #ffffff;
	box-shadow: $shadow-elevation-1;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	position: relative;

	@include respond-below(md) {
		width: 48px;
		height: 48px;
	}

	&__icon {
		color: $color-black-60;
		transition: color 0.3s;
	}

	&:hover & {
		&__icon {
			color: $color-black;
		}
	}
}

.item {
	position: absolute;
	bottom: 0;
	opacity: 0;
	pointer-events: none;
	width: 56px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $color-red;
	border-radius: 50%;
	transition: all 0.2s;
	z-index: 1;

	@include respond-below(md) {
		left: 0;
		width: 48px;
		height: 48px;
	}

	@include respond-above(md) {
		right: 0;
	}

	&_visible {
		pointer-events: auto;
		opacity: 1;

		@include respond-below(md) {
			left: calc(100% + 30px);
		}

		@include respond-above(md) {
			right: calc(100% + 30px);
		}
	}

	&_chat {
		background-color: $color-red;
	}

	&__icon {
		color: $color-white;
	}
}

.item_visible.item_1 {
	@include respond-below(md) {
		left: 0;
	}

	@include respond-above(md) {
		right: 0;
	}

	bottom: calc(100% + 30px);
}

.item_visible.item_2 {
	bottom: calc(100% + 10px);

	@include respond-below(md) {
		left: calc(100% + 10px);
	}

	@include respond-above(md) {
		right: calc(100% + 10px);
	}
}
