@import "../../../../assets/scss/mixins";

.about {
  display: flex;
  flex-direction: column;
}

.text {
  font-style: normal;
  font-weight: 400;
  color: rgba(28, 28, 30, 0.60);

  @include respond-above(sm) {
    font-size: 18px;
    line-height: 24px;
    margin: 24px 0 0;
  }

  @include respond-below(sm) {
    font-size: 15px;
    line-height: 20px;
    margin: 16px 0 0;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 48px;

  @include respond-above(sm) {
    margin-top: 40px;
  }

  @include respond-below(sm) {
    row-gap: 24px;
    margin-top: 24px;
    flex-direction: column;
  }
}

.item {
  display: flex;
  flex-direction: column;
  width: 376px;
  gap: 8px;

  @include respond-below(sm) {
    width: 100%;
  }

  &__title {
    font-weight: 600;

    @include respond-above(sm) {
      font-size: 40px;
      line-height: 44px;
    }

    @include respond-below(sm) {
      font-size: 28px;
      line-height: 34px;
    }

    &_red {
      color: #F4303B;
    }
  }

  &__text {
    color: rgba(28, 28, 30, 0.60);

    @include respond-above(sm) {
      font-size: 18px;
      line-height: 24px;
    }

    @include respond-below(sm) {
      font-size: 15px;
      line-height: 20px;
    }
  }
}