@import "../../../../assets/scss/mixins";

.banner {
  border-radius: 24px;

  @include respond-below(sm) {
    height: 120px;
    width: 100%;
    object-fit: none;
  }
}