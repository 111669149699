@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.wrapper {
  input {
    height: 47px;
  }
  legend {
    width: auto;
    border-bottom: none;
  }

  :global {
    .icon-warning {
      display: none;
    }
  }
}
