@import '../../../../assets/scss/mixins';

.section {
	display: flex;
	flex-direction: column;

	@include respond-above(sm) {
		gap: 40px;
	}

	@include respond-below(sm) {
		gap: 24px;
	}
}

.block {
	box-shadow: 0 2px 8px 0 rgba(28, 28, 30, 0.1);
	display: flex;
	flex-direction: column;
	position: relative;

	@include respond-above(sm) {
		padding: 24px 32px;
		border-radius: 24px;
	}

	@include respond-below(sm) {
		border-radius: 16px;
		padding: 16px 24px;
	}
}

.block_intro {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.title {
	font-weight: 500;
	margin: 0;

	@include respond-above(sm) {
		font-size: 24px;
		line-height: 30px;
	}

	@include respond-below(sm) {
		font-size: 17px;
		line-height: 22px;
	}

	&_red {
		color: #f4303b;
	}

	&_smile {
		background-image: url('smile.png');
		background-size: 30px auto;
		background-repeat: no-repeat;
		align-self: flex-start;

		@include respond-above(sm) {
			padding-right: 38px;
			background-position: right center;
		}

		@include respond-below(sm) {
			background-position: left top;
			padding-top: 38px;
		}
	}
}

.text {
	color: rgba(28, 28, 30, 0.6);

	@include respond-above(sm) {
		font-size: 18px;
		line-height: 24px;
	}

	@include respond-below(sm) {
		font-size: 15px;
		line-height: 20px;
	}
}

.calc {
	display: flex;
	flex-direction: column;

	@include respond-above(sm) {
		gap: 24px;
	}

	@include respond-below(sm) {
		gap: 16px;
	}
}

.info {
	color: #777778;
	display: block;

	@include respond-above(sm) {
		font-size: 15px;
		line-height: 21px;
		margin-top: 24px;
		padding: 0 32px;
	}

	@include respond-below(sm) {
		font-size: 13px;
		line-height: 16px;
		padding: 0 24px;
		margin-top: 8px;
	}
}

.separator {
	height: 1px;
	background-color: #ececf0;
	margin: 24px 0;
}

.bonuses {
	display: flex;
	gap: 24px;

	@include respond-below(sm) {
		flex-direction: column;
	}
}

.bonus {
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1;
	flex-shrink: 0;

	&__title {
		font-weight: 500;

		@include respond-above(sm) {
			font-size: 18px;
			line-height: 24px;
		}

		@include respond-below(sm) {
			font-size: 17px;
			line-height: 22px;
		}
	}

	&__description {
		color: #777778;

		@include respond-above(sm) {
			font-size: 18px;
			line-height: 24px;
		}

		@include respond-below(sm) {
			font-size: 15px;
			line-height: 20px;
		}
	}
}

.split {
	display: flex;

	@include respond-above(sm) {
		align-items: center;
		justify-content: space-between;
		gap: 24px;
	}

	@include respond-below(sm) {
		flex-direction: column;
		gap: 8px;
	}
}

.chevron {
	width: 12px;
	height: 12px;
	transform: rotate(90deg);
}

.checkboxes {
	display: flex;
	flex-direction: column;
	gap: 24px;

	&__title {
		font-weight: 500;

		@include respond-above(sm) {
			font-size: 18px;
			line-height: 24px;
		}

		@include respond-below(sm) {
			font-size: 15px;
			line-height: 20px;
		}
	}

	&__row {
		display: flex;
		align-items: center;
	}

	&__name {
		margin: 0 8px 0 16px;

		@include respond-above(sm) {
			font-size: 18px;
			line-height: 24px;
		}

		@include respond-below(sm) {
			font-size: 15px;
			line-height: 20px;
		}
	}

	&__check {
		flex-shrink: 0;
		width: 24px;
		height: 24px;
	}
}
