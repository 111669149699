@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.wrapper {
	flex-grow: 1;
	background-color: #ffffff;
	border-radius: 16px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	@include respond-below(xs) {
		padding: 0 16px;
	}

	* {
		box-sizing: border-box;
	}

	&.content {
		display: flex;
		max-width: 1416px;
		@include respond-below(sm) {
			display: block;
		}
	}

	.breadCrumbs {
		padding-top: 24px;

		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		font-size: 15px;
		color: rgba(28, 28, 30, 0.6);

		@include respond-below(xs) {
			padding-top: 0;
		}

		span:first-child {
			cursor: pointer;

			@include hover {
				color: #1c1c1e;
			}
		}

		&__mobile {
			display: flex;
			align-items: center;
			gap: 8px;

			cursor: pointer;

			@include hover {
				color: #1c1c1e;

				& i {
					color: #1c1c1e;
				}
			}
		}

		&__chevron {
			color: $color-gray-1;
			transform: rotate(-90deg);

			@include respond-below(sm) {
				transform: rotate(90deg);
			}
		}
	}

	h2 {
		font-weight: 600;
		font-size: 24px;
	}

	.infoPage {
		padding-right: 0;
		@include respond-below(md) {
			padding-left: 0;
		}

		h2 {
			padding-top: 48px;

			font-size: 34px;
			margin-bottom: 0;
			line-height: 38px;

			@include respond-below(xs) {
				padding-top: 24px;
			}
		}

		h3 {
			margin-top: 40px;
			margin-bottom: 0;
			font-size: 24px;
			line-height: 32px;
			font-weight: 600;
		}

		p {
			margin-top: 24px;
			margin-bottom: 0;

			font-size: 15px;
			line-height: 24px;
		}

		.blockInfo {
			display: flex;
			max-width: 880px;
			padding: 12px 16px;
			border-radius: 12px;
			background: #f4f4f4;
			gap: 16px;
			margin-top: 36px;

			&__gold {
				background: rgba(255, 159, 10, 0.1);

				i {
					color: #ff9100;
				}
			}

			span {
				max-width: 732px;
				font-size: 15px;
			}

			i {
				margin-top: 2px;
			}
		}

		.link {
			color: #0071e6;
			cursor: pointer;

			@include hover {
				color: #5199f2;
			}
		}

		ul,
		ol {
			margin-top: 16px;
			display: flex;
			flex-direction: column;
			gap: 8px;

			& li {
				font-size: 15px;
				line-height: 24px;
			}
		}

		img {
			max-width: 100%;
			margin-top: 24px;
		}
	}

	.wrapperContentFaq {
		max-width: 800px;
		margin: 0 24px 24px 40px;
		overflow: hidden;
		@include respond-below(md) {
			max-width: unset;
			margin: 24px;
		}
		@include respond-below(xs) {
			margin: 32px 0 40px;

			&__search {
				margin-top: 0;
			}
		}
	}

	.mainLink {
		padding: 10px 12px;
		font-size: 15px;
		font-weight: 600;
		cursor: pointer;
		border-radius: 8px;
		transition: 0.3s;

		@include hover {
			background-color: #f4f4f4;
		}
	}

	.table {
		margin-top: 24px;
		overflow-x: auto;

		table {
			width: 800px;
		}

		th {
			border: none;
			font-weight: 500;
			font-size: 14px;
			color: rgba(28, 28, 30, 0.6);

			&::before {
				display: none;
			}
		}

		tr {
			:global {
				.ant-table-cell-row-hover {
					background: transparent !important;
				}
			}

			td {
				font-size: 15px;

				&:first-child {
					font-weight: 600;
				}
			}
		}
	}

	.downloadIcon {
		color: #b5b5b8;

		@include hover {
			color: #72767d;
		}
	}

	.wrapperBlockSecondary {
		display: flex;
		gap: 4px;
		margin-top: 40px;

		.iconStandart {
			margin-top: 6px;
			font-size: 24px;
		}

		p,
		h3 {
			margin: 0;
		}
	}
}
