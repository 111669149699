.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  .thanksInfo {
    padding: 24px;
    background: #F4F4F5;
    border-radius: 12px;
    max-width: 400px;
    width: 100%;

    div {
      display: flex;
      gap: 12px;

      svg {
        margin-top: 5px;
      }

      h2 {
        color: #1C1C1E;
        font-weight: 600;
        font-size: 20px;
      }
    }

    p {
      font-size: 16px;
      margin: 0;
    }
  }
}