@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/variables';

.wrapperHeader {
	position: fixed;
	top: 0;
	z-index: 111;
	width: 100%;
	display: flex;
	flex-direction: column;
	left: 50%;
	transform: translateX(-50%);
	background-color: #ffffff;
}

.hideLink {
	visibility: hidden;
	height: 0;
	opacity: 0;
	font-size: 0;
	width: 0;
}

.logo {
	@include respond-above(md) {
		width: 175px;
		height: 28px;
	}

	@include respond-below(md) {
		width: 130px;
		height: 24px;
	}
}

.header {
	max-width: 960px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
}

.header_content {
	max-width: 960px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 40px;
	padding: 16px;
	margin: 0 auto;
	box-sizing: border-box;
	border-bottom: 1px solid $color-gray-4;

	@include respond-below(sm) {
		padding: 12px 16px;
		border-bottom: none;
	}
}

.wrapperNavigation {
	max-width: 960px;
	width: 100%;
	margin: 0 auto;
	overflow-x: auto;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
	.navigation {
		display: flex;

		gap: 24px;
		padding: 12px;

		a {
			display: block;

			color: $color-black-60;
			text-wrap: nowrap;
			transition: none;

			font-size: 15px;
			line-height: 20px;

			&:last-child {
				padding-right: 12px;
			}

			@include hover {
				color: $color-black;
			}
		}
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}

.telegram_link {
	margin-left: 16px;
}

.actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;

	@include respond-below(sm) {
		position: absolute;
		left: 0px;
		right: 0px;
		top: 58%;
		box-shadow: 0 12px 20px 0 rgba(28, 28, 30, 0.03);
		z-index: 1;
		padding: 16px;
		background-color: #ffffff;
		flex-direction: column;
		gap: 16px;
		border-radius: 0 0 16px 16px;
		opacity: 1;
		transition: opacity 0.1s;

		&:not(&_opened) {
			opacity: 0;
			pointer-events: none;
		}
	}
}

.faq {
	background-color: transparent;
	border: 0;
	font-size: 15px;
	font-weight: 500;
	line-height: 20px;
	display: flex;
	gap: 4px;
	padding: 0;
	align-items: center;
	cursor: pointer;
	color: #1c1c1e;

	& > svg > path {
		color: #1c1c1e;
	}

	@include hover {
		color: #777778;

		& > svg > path {
			color: #777778;
		}
	}
}

.chevron {
	width: 12px;
	height: 12px;
	transform: rotate(90deg);

	path {
		stroke: #777778;
	}
}

.menu {
	width: 24px;
	height: 24px;
	border: 0;
	background-color: transparent;
	padding: 0;

	@include respond-above(sm) {
		display: none;
	}
}

.burger {
	cursor: pointer;

	path {
		transition: transform 0.2s ease-in-out;
	}

	path:first-child {
		transform-origin: 5px 9px;
	}

	path:last-child {
		transform-origin: 5px 15px;
	}

	&_cross {
		path:first-child {
			transform: rotate(45deg);
		}

		path:last-child {
			transform: rotate(-45deg);
		}
	}
}

.overlay {
	display: none;
}

.overlay_active {
	display: block;
	position: fixed;
	inset: 0px;

	background: rgba(28, 28, 30, 0.32);
	z-index: 2;
}
