@import "../../assets/scss/mixins";

.notification {
  border-radius: 20px;
  background: red;
}

.rating {
  line-height: 0;
  margin-top: 8px;
  pointer-events: none;
  margin-left: -2px;
}

.wrapperModal {
  width: 311px!important;


  h2 {
    font-size: 15px;
    line-height: 24px;
  }

  .modal{
    align-items: center;
  }

  :global {

    i {
      color: #fff;
      padding: 16px;
      background: #52C41A;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
    }

    button {
      width: 100%;
      font-weight: 500;
    }
  }
}

.warningIconCustom {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: #FAAD14;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.wrapper {
  p {
    font-size: 13px;
    color: #757576;
    margin: 0;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  max-width: 680px;
  padding: 0 20px;
  width: 100%;
  margin: 64px auto;
  display: flex;
  flex-direction: column;

  .logo {
    width: 155px;
    align-self: center;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 32px;
    margin-bottom: 16px;
    color: #1C1C1E;
  }

  .wrapperReview {
    padding: 16px;
    border-radius: 12px;
    background: #F4F4F5;
  }

  .aboutOffer {
    display: flex;
    gap: 12px;

    @include respond-below(xs) {
      flex-wrap: wrap;
    }

    .offerImg {
      width: 20%;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .name {
        font-size: 15px;
        line-height: 20px;
        color: #1C1C1E;
      }
    }
  }

  .line {
    height: 1px;
    background: #ECECF0;
    margin: 16px 0;
  }

  .nameReviewer {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #1C1C1E;

    span {
      text-decoration: none;
      color: #757576;
      font-weight: 400;
    }
  }

  .textReview {
    font-size: 15px;
    line-height: 20px;
    color: #1C1C1E;
    margin-top: 8px;
  }

  .textInfo {
    margin: 8px 0 0 16px;
  }

  .textForTextarea {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #1C1C1E;
    margin-top: 32px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;

    .textarea {
      margin-top: 16px;
      width: 100%;
    }

    .btnSubmit {
      margin-top: 16px;
      width: fit-content;
      border-radius: 13px;
    }
  }

  .infoFooter {
    margin-top: 32px;

    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .warningIcon {
      color: white;
      background: #FAAD14;
      border-radius: 50%;
    }

    p {
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      color: #1C1C1E;
    }

    ul {
      padding-left: 20px;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      li {
        color: #000000;
        font-size: 14px;
        line-height: 18px;

        span {
          color: #1677FF;
          cursor: pointer;
        }
      }
    }
  }
}