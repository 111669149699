@import '../../../../assets/scss/mixins';

.footer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px;
	border-top: 1px solid #ececf0;
	margin-top: 64px;
	gap: 10px;
}

.telegram_link {
	color: #1c1c1e;
	display: flex;
	gap: 8px;
	align-items: center;
	font-size: 15px;
	font-weight: 500;
	line-height: 20px;

	@include hover {
		color: #1c1c1e;
	}
}
