@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.wrapper__modal {
  align-items: center;
  text-align: center;
  :global {
    i {
      color: $color-green;
      padding: 16px;
      background: $color-green-10;
      border-radius: 50%;
    }

    button {
      width: 100%;
      font-weight: 500;
    }
  }
}

.wrapper__modalMain {
  @include respond-below(sm) {
    background: $color-white;
  }
  img {
    width: 100%;
  }
}

.modalStyle {
  width: 520px;
  border-radius: 18px;

  :global {
    .ant-form-item-explain-error {
      padding-bottom: 10px;
    }
    @include respond-below(sm) {
      width: 100% !important;
      max-width: 100% !important;

      height: 100%;
      margin: 0;
      padding: 0;
      top: 0;
      max-width: none;
      .ant-modal-wrap {
        background: $color-white !important;
      }

      .ant-modal-content {
        height: 100%;
        box-shadow: none;
      }

      .ant-modal-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px 16px;

        h2 {
          margin-bottom: 30px;
        }
      }
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    @include respond-below(sm) {
      height: 100%;
    }

    .textArea {
      textarea {
        padding: 0 10px 10px 10px;
        margin: 10px 0 0 0;
      }
    }

    :global {
      .ant-input-textarea-show-count::after {
        margin-left: 12px;
      }
    }
  }

  :global {
    .ant-modal-content {
      border-radius: 18px;
    }
    @include respond-below(sm) {
      .ant-modal-content {
        border-radius: 0;
      }
    }
  }

  .text {
    display: block;
    font-size: 11px;
    line-height: 14px;
    color: $color-black-60;
  }
}

.wrapper__button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 18px;
  margin-top: auto;
  button {
    width: 47%;
  }
}

.upload__files {
  border: none !important;
  background: transparent !important;
  margin-top: 12px;

  :global {
    .ant-upload {
      color: $color-blue;
      font-size: 15px;
      padding: 0 !important;
    }

    .ant-upload-list {
      display: flex;
    }

    .ant-upload-list-picture-card-container {
      margin: 0 !important;
    }
  }
}

.cancel__btn.cancel__btn {
  background-color: $color-background-2;
  border-color: $color-background-2;

  @include hover {
    background-color: #d8d8dd !important;
    border-color: #d8d8dd !important;
  }
}

.bottom__wrapper {
  display: flex;
  flex-direction: column;
}
