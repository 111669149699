@import '../../../../assets/scss/mixins';

.mission {
	display: flex;
	flex-direction: column;

	@include respond-above(sm) {
		gap: 40px;
	}

	@include respond-below(sm) {
		gap: 24px;
	}
}

.top {
	display: flex;
	width: 100%;

	@include respond-above(sm) {
		gap: 40px;
		align-items: center;
	}

	@include respond-below(sm) {
		flex-direction: column-reverse;
		gap: 24px;
	}
}

.left {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.image {
	flex-shrink: 0;
	width: 500px;
	height: 300px;

	@include respond-below(md) {
		width: 400px;
		height: 233px;
	}

	@include respond-below(sm) {
		width: 100%;
		height: auto;
	}
}

.text {
	margin: 0;
	color: rgba(28, 28, 30, 0.6);

	@include respond-above(sm) {
		font-size: 18px;
		line-height: 24px;
	}

	@include respond-below(sm) {
		font-size: 15px;
		line-height: 20px;
	}
}

.bottom {
	display: flex;

	@include respond-below(sm) {
		flex-direction: column;
		gap: 32px;
	}
}

.item {
	display: flex;
	position: relative;
	flex: 1;

	@include respond-above(sm) {
		gap: 16px;
		flex-direction: column;
	}

	@include respond-below(sm) {
		gap: 12px;
		align-items: center;
	}
}

.title {
	@include respond-above(sm) {
		font-size: 18px;
		line-height: 24px;
	}

	@include respond-below(sm) {
		font-size: 15px;
		line-height: 20px;
	}
}

.count {
	background-color: #f4f4f5;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	position: relative;
	z-index: 1;
	border-radius: 50px;

	@include respond-above(sm) {
		width: 64px;
		height: 40px;
		font-size: 18px;
		line-height: 24px;
	}

	@include respond-below(sm) {
		width: 56px;
		height: 40px;
		font-size: 15px;
		line-height: 20px;
	}
}

.bottom .item:not(:last-child) {
	&:after {
		content: '';
		position: absolute;
		border: 1px dashed #d8d8dd;

		@include respond-above(sm) {
			left: 64px;
			right: 0;
			top: 20px;
		}

		@include respond-below(sm) {
			top: 40px;
			left: 28px;
			bottom: -32px;
		}
	}
}