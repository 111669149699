@import '../../assets/scss/mixins';

.what {
	display: flex;
	flex-direction: column;

	@include respond-above(sm) {
		gap: 40px;
	}

	@include respond-below(sm) {
		gap: 24px;
	}
}

.top {
	display: flex;
	width: 100%;
	gap: 40px;
	align-items: center;
	flex-direction: row-reverse;

	@include respond-below(sm) {
		flex-direction: column-reverse;
		align-items: flex-start;
		gap: 24px;
	}
}

.left {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.image {
	flex-shrink: 0;
	width: 500px;
	height: 300px;

	@include respond-below(md) {
		width: 400px;
		height: 233px;
	}

	@include respond-below(sm) {
		width: 100%;
		height: auto;
	}
}

.text {
	margin: 0;
	color: rgba(28, 28, 30, 0.6);

	@include respond-above(sm) {
		font-size: 18px;
		line-height: 24px;
	}

	@include respond-below(sm) {
		font-size: 15px;
		line-height: 20px;
	}
}

.bottom {
	display: grid;

	@include respond-above(sm) {
		grid-template-columns: 1fr 1fr;
		gap: 40px 48px;
	}

	@include respond-below(sm) {
		grid-template-columns: 1fr;
		gap: 24px;
	}
}

.item {
	display: flex;
	flex-direction: column;

	@include respond-above(sm) {
		gap: 12px;
	}

	@include respond-below(sm) {
		gap: 8px;
	}
}

.title {
	display: flex;
	align-items: center;
	font-weight: 500;

	@include respond-above(sm) {
		gap: 16px;
		font-size: 24px;
		line-height: 30px;
	}

	@include respond-below(sm) {
		gap: 12px;
		font-size: 17px;
		line-height: 22px;
	}
}

.cross {
	@include respond-below(sm) {
		width: 24px;
		height: 24px;
	}
}

.description {
	color: rgba(28, 28, 30, 0.6);

	@include respond-above(sm) {
		font-size: 18px;
		line-height: 24px;
		padding-left: 48px;
	}

	@include respond-below(sm) {
		font-size: 15px;
		line-height: 20px;
		padding-left: 36px;
	}
}

.button {
	@include respond-below(sm) {
		width: 100%;
	}
}

.requirements {
	display: flex;
	padding: 16px 24px;
	box-shadow: 0 2px 6px 0 rgba(28, 28, 30, 0.1);
	border-radius: 16px;
	flex-direction: column;

	@include respond-above(sm) {
		gap: 8px;
	}

	@include respond-below(sm) {
		gap: 24px;
	}

	&__links {
		display: flex;
		flex-wrap: wrap;

		@include respond-above(sm) {
			gap: 32px;
		}

		@include respond-below(sm) {
			gap: 24px;
			flex-direction: column;
		}
	}

	&__link {
		font-weight: 500;
		color: #777778;
		display: flex;
		gap: 8px;
		align-items: center;

		@include hover {
			color: #1c1c1e;
		}

		@include respond-above(sm) {
			font-size: 15px;
			line-height: 21px;
		}

		@include respond-below(sm) {
			font-size: 13px;
			line-height: 16px;
		}
	}

	&__icon {
		color: #777778;
		flex-shrink: 0;
	}

	&__text {
		@include respond-above(sm) {
			font-size: 18px;
			line-height: 24px;
		}

		@include respond-below(sm) {
			font-size: 15px;
			line-height: 20px;
		}
	}
}
