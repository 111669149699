@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/variables';

.overlay {
	visibility: hidden;
	@include respond-below(sm) {
		&.active {
			visibility: visible;
			position: fixed;
			inset: 0;
			background: rgba(0, 0, 0, 0.3);
			z-index: 1;
		}
		@include respond-below(xs) {
			display: none;
		}
	}
}

.scroll {
	z-index: 1;
	position: sticky;
	top: 61px;
	bottom: 0;
	width: 280px;
	height: calc(100vh - 61px);
	padding: 24px;
	padding-left: 0;

	:global {
		div[data-overlayscrollbars-contents] {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	@include respond-below(md) {
		width: 272px;
		padding-right: 16px;
	}

	@include respond-below(sm) {
		width: 100%;
		padding: 24px 8px;
		height: 100vh;
	}

	@include respond-below(xs) {
		height: calc(100vh - 56px);
		height: calc(100svh - 56px);

		padding: 32px 4px 24px;
	}
}

.wrapperLeftCol {
	position: relative;
	display: flex;
	flex-direction: column;

	@include respond-below(xl) {
		padding-left: 8px;
	}

	@include respond-below(sm) {
		left: -280px;
		visibility: hidden;
		transition: 0.3s;
		position: fixed;
		top: 0;
		min-width: 280px;
		max-width: 280px;
		margin: 0;
		background-color: #fff;
		padding-left: 0;
		@include respond-below(xs) {
			left: -100%;
		}
		&.active {
			visibility: visible;
			left: 0;
			z-index: 1111;
		}
	}

	@include respond-below(xs) {
		width: 100%;
		max-width: none;
		top: 58px;
	}

	&::before {
		content: '';
		width: 1px;
		background: #ececf0;
		height: calc(100% + 24px);
		position: absolute;
		right: 0;
		top: -24px;

		@include respond-below(sm) {
			width: 0;
		}
	}

	.activeItem {
		background-color: #f4f4f5;
		border-radius: 8px;
	}

	span {
		font-weight: 600;
		font-size: 15px;
	}

	.headerText {
		padding: 10px 44px 10px 12px;
		width: 100%;
		margin: 0;
	}

	[class='ant-collapse-expand-icon'] {
		position: absolute;
		z-index: 111;
		right: 0;
	}

	[class~='ant-collapse-header'] {
		padding: 0 !important;
		border-radius: 8px !important;
		transition: 0.3s;
		cursor: pointer !important;

		@include hover {
			background-color: #f4f4f5;
		}
	}

	[class~='ant-collapse-content-box'] {
		padding: 0 !important;
	}

	.collapse {
		background-color: white;
		border: none;
		padding-bottom: 24px;

		.panel {
			border: none;
			position: relative;

			[class*='ant-collapse-expand-icon'] {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 44px;
				height: 100%;

				.icon {
					color: #b5b5b8;
					transition: 0.3s;

					&__active {
						transform: rotate(-180deg);
					}
				}

				@include hover {
					.icon {
						color: $color-black-60;
					}
				}
			}

			.panelContent {
				display: flex;
				flex-direction: column;
				padding-left: 16px;

				.item {
					width: 100%;
					padding: 10px 12px;
					border-radius: 8px;
					cursor: pointer;

					@include hover {
						background-color: #f4f4f5;
					}

					span {
						color: #1c1c1e;
						font-size: 15px;
						font-weight: normal;
					}
				}
			}
		}
	}
}

.notice {
	z-index: 1;

	max-width: 256px;
	margin: 0;
	padding: 16px 56px 16px 16px;
	font-size: 15px;
	line-height: 20px;
	background: url('../../../../../public/assets/images/letter.svg') no-repeat right 16px center #fff;
	border: 1px solid #d8d8dd;
	border-radius: 12px;

	@include respond-below(sm) {
		left: 16px;
		max-width: 248px;
	}

	@include respond-below(xs) {
		left: 16px;
		right: 16px;
		max-width: unset;

		margin: 0 12px;
	}

	.link {
		font-weight: 400;
	}
}

.link {
	color: #0071e6;
	cursor: pointer;

	@include hover {
		color: #5199f2;
	}
}
