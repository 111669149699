@import '../../../../assets/scss/mixins';

.mission {
	display: flex;
	flex-direction: column;

	@include respond-above(sm) {
		gap: 40px;
	}

	@include respond-below(sm) {
		gap: 24px;
	}
}

.top {
	display: flex;
	width: 100%;
	gap: 40px;
	align-items: center;

	@include respond-below(sm) {
		flex-direction: column-reverse;
		align-items: flex-start;
		gap: 24px;
	}
}

.left {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.image {
	flex-shrink: 0;
	width: 500px;
	height: 300px;

	@include respond-below(md) {
		width: 400px;
		height: 233px;
	}

	@include respond-below(sm) {
		width: 100%;
		height: auto;
	}
}

.text {
	margin: 0;
	color: rgba(28, 28, 30, 0.6);

	@include respond-above(sm) {
		font-size: 18px;
		line-height: 24px;
	}

	@include respond-below(sm) {
		font-size: 15px;
		line-height: 20px;
	}
}

.bottom {
	display: grid;

	@include respond-above(sm) {
		grid-template-columns: 1fr 1fr;
		gap: 40px 48px;
	}

	@include respond-below(sm) {
		grid-template-columns: 1fr;
		gap: 24px;
	}
}

.item {
	display: flex;
	flex-direction: column;

	@include respond-above(sm) {
		gap: 12px;
	}

	@include respond-below(sm) {
		gap: 8px;
	}
}

.title {
	display: flex;
	align-items: flex-start;
	font-weight: 500;

	@include respond-above(sm) {
		gap: 16px;
		font-size: 24px;
		line-height: 30px;
	}

	@include respond-below(sm) {
		gap: 12px;
		font-size: 17px;
		line-height: 22px;
	}
}

.check {
	flex-shrink: 0;

	@include respond-below(sm) {
		width: 24px;
		height: 24px;
	}
}

.description {
	color: rgba(28, 28, 30, 0.6);

	@include respond-above(sm) {
		font-size: 18px;
		line-height: 24px;
	}

	@include respond-below(sm) {
		font-size: 15px;
		line-height: 20px;
		padding-left: 36px;
	}
}

.button {
	@include respond-below(sm) {
		width: 100%;
	}
}

.link {
    color: #0a84ff;
	cursor: pointer;
}